h5, a {
    text-align: center;
    color: #8D858C;
}
  
.menu-container {
  display:flex;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1%;
  gap: 1em;
  justify-content: flex-end;
  align-items: center;
}

input {
    margin-top: 10px;
    padding: 10px;
    width: 95%;
    max-width: 400px;
    min-width: 45px;
    border-radius: 5px;
    border: 1px solid #ccc;
}
  
th, td {
    padding: 8px 12px;
    text-align: left;
    color: #ffffff;
}
  
  .filter {
    max-width: 5%;
    min-width: 5%;
    padding-left: 52%;
    padding-right: 1em;
  }
  
  .search-bar {
    max-width: 25%;
    min-width: 10%;
    padding-left: 40%
  }
  
  .team-table{
    margin: 0 auto;
    border-collapse: collapse;
    border: none;
    width: 70%;
  }

  .team-table th {
    background-color: #362431;
  }
  
  .team-table tr:nth-child(odd) {
    background-color: #6f6f6f;
  }
  
  .team-table tr:nth-child(even) {
    background-color: #2f2f2f;
  }
  
  input[type="text"] {
    font-size: 16px;
  }
  
  .filter-container {
    position: relative;
    color: #362431;
  }
  
  .filter-icon {
    font-size: 24px;
    cursor: pointer;
    margin-left: 10px;
    color: #fff;
  }
  
  .filter-menu {
    position: absolute;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 75px;
    padding: 10px;
    z-index: 100;
  }
  
  .filter-menu h3 {
    margin: 0;
    font-size: 16px;
  }
  
  .filter-label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  input[type="checkbox"] {
    accent-color: #362431;
  }
  
  .team-photo {
    width: 50px;
    height: auto;
    border-radius: 5px;
  }
  
  button {
    padding: 10px 10px;
    margin: 0px 5px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Disabled button styles */
  button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .buttons-container {
    display: flex;
    justify-content: flex-start;
    width: 70%;
    margin-top: 0.5%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .team-fullname {
    display: contents;
  }
  
  .team-shortname {
    display: none;
  }
  
  @media (max-width: 800px) {
    .team-fullname {
      display: none;
    }
  
    .team-shortname {
      display: contents;
    }
  
    h1 {
      font-size: 1em;
    }
  
    .filter {
      width: 5%;
    }

    .buttons-container{
      justify-content: center;
    }
  }