.navbar-container {
  display: flex;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  gap: 1em;
  position: relative;
  z-index: 10;
}

.navbar-container .logo {
  margin-top: 1%;
  width: 5%;
  height: 5%;
}

.navbar-container .nav-links {
  flex-grow: 1;
  display: flex;
  gap: 2em;
}

.navbar-container .page-title {
  color: white;
}

.navbar-container a {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 5px;
  border-radius: 25px;
}

.navbar-container a:hover {
  color: #8D858C;
  border-color: #8D858C;
}

.hamburger {
  display: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #ffffff;
}

@media (max-width: 800px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 93%;
    background-color: #333;
    padding: 1em;
    border-radius: 0 0 10px 10px;
    z-index: 1000;
    text-align: center;
  }

  .nav-links.open {
    display: flex; /* Show the menu when it's open */
  }

  .nav-links:not(.open) {
    display: none; /* Hide the menu when it's not open */
  }

  .hamburger {
    display: block;
    z-index: 1001;
  }

  .navbar-container {
    width: 90%;
  }

  h1 {
    font-size: 0.85em;
  }
}